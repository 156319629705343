import { LANG } from "../actions/LangActions";

const initialState = {
  lang: localStorage.getItem("pickmeLang") || "en",
};

export function LangReducer(state = initialState, action) {
  if (action.type === LANG) {
    return {
      lang: action.payload,
    };
  }
  return state;
}
