import { useEffect, useState } from "react";
import ServicesService from "../../services/ServicesService";
import CustomLoader from "../../layouts/CustomLoader";
import { useSelector } from "react-redux";

const Services = () => {
    const [data, setData] = useState([])
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state => state?.lang?.lang)

    useEffect(()=>{
        setLoading(true)
        new ServicesService()?.getList().then(res=>{
            if(res?.status){
                setData(res?.data?.data)
                if(res?.data?.data?.length === 0){
                    setHasData(0)
                } else {
                    setHasData(1)
                }
            }
            setLoading(false)
        })
    },[])

    if(loading){
        return <div className="container d-flex justify-content-center" style={{paddingTop: '15rem'}}>
            <CustomLoader />
        </div>
    }
    return <div className="services">
        <div className="container mil-p-120-30">
            <div className="row mil-mb-60">
                <div className="col-lg-6 col-xl-8">
                    <h2 className="mil-up mil-muted">Services</h2>
                </div>
            </div>
            
            <div className="row">
            {hasData === 1 && data?.map(service=>{
                return <div key={service?.id} className="col-lg-4 mb-4">
                    <a href={service.url} target="_blank" state={service} className="mil-service-card mil-mb-60" rel="noreferrer">
                        <div className="mil-cover-frame mil-up">
                            <img src={service?.image} alt="cover" className="w-100 lazyload" />
                        </div>
                        <div className="mil-post-descr text-center">
                            <h4 className="mil-up mil-mb-15 mt-4 mil-muted">{service[`title_${lang}`]}</h4>
                        </div>
                    </a>
                </div>
            })}
            {hasData === 0 && <h4 className="mil-up mil-muted mt-4">No services</h4>}
            </div>
        </div>
    </div>
}
export default Services;