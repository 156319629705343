import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/gallery";

export default class GalleryService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getList(type) {
        return http.get(`${apiEndpoint}?type=${type?.type}`);
    }
    getFashionList() {
        return http.get(`${apiEndpoint}/fashion`);
    }
}
