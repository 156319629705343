import React, { useEffect, useState } from "react";
import GalleryService from "../../services/GalleryService";


const Tab = ({type}) => {
    const [imgs, setImgs] = useState([])
    const [hasData, setHasData] = useState(null)
    const galleryService = new GalleryService()

    useEffect(()=>{
        if(type === 'fashion'){
            galleryService.getFashionList().then(res =>{
                if(res?.status){
                    let data = res?.data?.data?.filter((item, index, self) =>
                        index === self.findIndex((t) => t.image === item.image)
                    ).sort((a, b) => {
                        const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                        return extractNumber(a.image) - extractNumber(b.image);
                    })
                    setImgs(data)
                    if(res?.data?.data?.length > 0){
                        setHasData(1)
                    } else {
                        setHasData(0)
                    }
                }
            })
        } else {
            galleryService.getList({type: type}).then(res=>{
                if(res?.status){
                    let data = res?.data?.data?.filter((item, index, self) =>
                            index === self.findIndex((t) => parseInt(t?.one.match(/\.com\/(\d+)-/)[1], 10) === parseInt(item?.one.match(/\.com\/(\d+)-/)[1], 10))
                        ).sort((a, b) => {
                            const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                            return extractNumber(a.one) - extractNumber(b.one);
                        })
                    setImgs(data)
                    if(res?.data?.data?.length > 0){
                        setHasData(1)
                    } else {
                        setHasData(0)
                    }
                }
            })
        }
    },[type])

    return <div className="mt-5">
            {hasData === 1 && (type === 'fashion' ? <div className="row">
                {imgs?.map((imgArray,index) => {
                return <React.Fragment key={index}>
                    <div className='col-6 p-1'>
                        <img src={imgArray?.image} alt={index} className="w-100 h-100" />
                    </div>
                </React.Fragment>
            })}
            </div> : imgs?.map((imgArray,index) => {
                return <div className="row" key={index}>
                    <div className='col-12 p-1'>
                        <img src={imgArray?.one} alt={index} className="w-100" />
                    </div>
                    <div className='col-6 p-1'>
                        <img src={imgArray?.two} alt={index} className="w-100" />
                    </div>
                    <div className='col-6 p-1'>
                        <img src={imgArray?.three} alt={index} className="w-100" />
                    </div>
                </div>
            }))}
            {hasData === 0 && <h4 className="mil-up mil-muted">No Images Available</h4>}
    </div>
}
export default Tab;