const VideoModal = ({ show, onClose, video }) => {
    if (!show) {
        return null;
      }
    
      const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      };
    
    return <div className="modal-backdrop" onClick={handleBackdropClick}>
    <div className="modal-content">
      <button onClick={()=> onClose()} className="modal-close">
        &times;
      </button>
      <div className="text-center">
        <video
          src={video}
          controls={true}
          className='h-75 video'
          style={{
            width: '85%'
          }}
          autoPlay
          loop
          playsInline
        />
      </div>
    </div>
  </div>
}
export default VideoModal