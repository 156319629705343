import React, { useEffect, useMemo, useState } from 'react'
import HomeService from '../../services/HomeService'
import logo from '../../assets/home-logo.webp'
import './style.css'
import Background from './Background'

const Home = () =>{
    const homeService = new HomeService()
    const [calssAnimate, setCalssAnimate] = useState("")
    const [data, setData] = useState([
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
        // {one: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', two: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg', three: 'https://www.davidhechler.com/wp-content/uploads/2016/07/500x500-dummy-image.jpg'},
    ])

    // useEffect(()=>{
    //     homeService.getList().then(res=>{
    //         if(res?.status){
    //             let data = res?.data?.data
    //             if(data?.length === 1) setPhotos([...data, ...data])
    //             if(data?.length >= 2) {
    //                 if(width > 500) setPhotos([...data])
    //                 if(width < 500) setPhotos([...data.slice(0,8)])
    //             }
    //         }
    //     })
    // },[])

    // const visibleImages = useMemo(() => {
    //     homeService.getList().then(res => {
    //         if(res?.status) {
    //             let fetchedData = res?.data?.data;
    //             setData([...fetchedData]);
    //         }
    //     }).catch(() => setData([]));
    // }, []);
    
    return <>
    <section className='mil-dark-bg'>        
        <div className="header mil-banner w-100">
            <div className="mil-banner-content mil-up">
                <img src={logo} alt='logo' width={500} />
            </div>
        </div>
        <Background />
    </section>
</>
}
export default Home