import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import geoAltFill from '../assets/geo-alt-fill.png'
import SettingService from '../services/SettingService';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../store/actions/LangActions';

const Navbar = () =>{
    const [data, setData] = useState({})
    const settingService = new SettingService()
    const [active, setActive] = useState(false)
    const lang = useSelector(state => state?.lang?.lang)
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(()=>{
        settingService.getList().then(res=>{
            if(res?.status === 200){
                setData(res?.data?.data)
            }
        })
    },[])

    const goToWhatsapp = () =>{
        let whatsappUrl = "https://wa.me/" + data?.whatsapp
        window.open(whatsappUrl);
    }

    const closeNavbar = () => {
        const nav = document.getElementById('navbar')
        const menuBtn = document.getElementById('menu-btn')
        // const menuBtn2 = document.getElementById('menu-btn2')

        setActive(false)
        nav.classList.remove('mil-active');
        menuBtn.classList.remove('mil-active');
        // menuBtn2.classList.remove('mil-active');
    }
    const openNavbar = () => {
        const nav = document.getElementById('navbar')
        const menuBtn = document.getElementById('menu-btn')
        // const menuBtn2 = document.getElementById('menu-btn2')

        setActive(false)
        nav.classList.toggle('mil-active');
        menuBtn.classList.toggle('mil-active');
        // menuBtn2.classList.add('mil-active');
    }

    const changeLanguage = (lan) => {
        dispatch(setLang(lan))
    }

    return <>
    <div className={`mil-frame ${active ? "fixed" : ''}`}>
          <div className="mil-frame-top" style={{pointerEvents: 'auto'}}>
            <div className='d-flex'>
              {/* <div className={`mil-menu-btn`} id='menu-btn2'>
                  <span></span>
              </div> */}
              <i className="fa fa-bars" style={{color: '#fff', fontSize: '1.8rem'}} onClick={openNavbar}></i>
              <div className='mx-4'>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className='p-0' style={{background: 'none', border: 'none'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" className="bi bi-translate " viewBox="0 0 16 16">
                            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
                            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
                        </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as="button" className={`${lang === 'en' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('en')}>
                        English
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'ar' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('ar')}>
                        اللغه العربيه
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'de' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('de')}>
                        Deutsch
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'fr' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('fr')}>
                        Française
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'ru' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('ru')}>
                        Pусский язык
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'it' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('it')}>
                        Italiana
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className={`${lang === 'es' ? 'active' : ''}`} style={{color: '#000'}} onClick={()=> changeLanguage('es')}>
                        Español
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
                
            </div>
            <div className='d-flex' style={{gap: '12px'}}>
                {!!data?.gmail && <a href={`mailto:${data?.gmail}`}>
                    <i className='fa fa-envelope' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede'}}></i>  
                </a> }
                {!!data?.address_link && <a href={data?.address_link} target='_blank' rel="noreferrer">
                    <img src={geoAltFill} alt='geoAltFill' className='lazyload' width={28} height={28} style={{cursor: 'pointer'}} />    
                </a> }             
            </div>
          </div>
          <div className="mil-frame-bottom">
              {location?.pathname === '/' && <div className="mil-social" 
                style={{
                    display: 'flex', 
                    alignItems: 'start', 
                    gap: '18px',
                    flexDirection: 'column-reverse',
                }}
                >
                    {!!data?.whatsapp && <i className='fab fa-whatsapp' onClick={goToWhatsapp} style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>}
                    {!!data?.facebook && <a href={data?.facebook} target='_blank'  rel="noreferrer">
                    <i className='fab fa-snapchat' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>
                    </a>}
                    {!!data?.tiktok && <a href={data?.tiktok} target='_blank'  rel="noreferrer">
                        <i className='fab fa-tiktok' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>
                    </a>}
                    {!!data?.instagram && <a href={data?.instagram} target='_blank'  rel="noreferrer">
                        <i className='fab fa-instagram' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>
                    </a>}
                    {!!data?.linked_in && <a href={data?.linked_in} target='_blank'  rel="noreferrer">
                        <i className='fab fa-linkedin' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>
                    </a>}
                    {!!data?.you_tube && <a href={data?.you_tube} target='_blank'  rel="noreferrer">
                        <i className='fab fa-youtube' style={{cursor: 'pointer',fontSize: '28px', color: '#dedede',}}></i>
                    </a>}
              </div>}
              <div className="mil-current-page">
              </div>
          </div> 
          
      </div>
    <div className={`mil-menu-frame ${active ? "mil-active" : ''}`} id='navbar'>
        <div className="mil-frame-top">
            <div className="mil-menu-btn" onClick={()=> {
                closeNavbar()
            }} id='menu-btn'>
                <span></span>
            </div>
        </div>
        <div className="container">
            <div className="mil-menu-content position-relative">
                <div className="row">
                    <div className="col-12">
                        <nav className="mil-main-menu" id="swupMenu">
                            <ul>
                                <li className="">
                                    <Link to='/' onClick={()=> closeNavbar()}>Homepage</Link>
                                </li>
                                <li className="">
                                    <Link to='/gallary' onClick={()=> closeNavbar()}>Gallery</Link>
                                </li>
                                <li className="">
                                    <Link to='/video' onClick={()=> closeNavbar()}>Video</Link>
                                </li>
                                <li className="">
                                    <Link to='/services' onClick={()=> closeNavbar()}>Services</Link>
                                </li>
                                <li className="">
                                    <Link to='/appointment' onClick={()=> closeNavbar()}>Appointment</Link>
                                </li>
                                <li className="">
                                    <Link to='/blogs' onClick={()=> closeNavbar()}>Blogs</Link>
                                </li>
                                <li className="">
                                    <Link to='/about' onClick={()=> closeNavbar()}>About us</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-12">
                        <div className='home-power' style={{color: '#fff',position: "absolute", bottom: '16px'}}>
                            <p className="mb-1">
                                All Rights Reserved by Picme studio
                            </p>
                            <p>
                                Powered By
                                <a rel="noreferrer" href="https://www.cloudliftsolution.com/" target="_blank" style={{color: "rgb(255, 255, 255)", textDecoration: "none", textWrap: 'nowrap', margin: "0px 2px"}}>
                                    Cloud Lift Solutions
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</>
}
export default Navbar;