import BaseService from "./BaseService";
import http from "./HttpService";
import { API_BASE_URL_ENV } from "../common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/video";

export default class VideoService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getList(type) {
    return http.get(`${apiEndpoint}?type=${type}`);
  }
}
