import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/blog";

export default class BlogsService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getList(type) {
        return http.get(`${apiEndpoint}?type=${type}`);
    }

    getBlogVideo() {
        return http.get(`${apiEndpoint}/blogVideo`);
    }
}
