import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const Blog = () => {
    const [blog, setBlog] = useState({})
    const location = useLocation()
    const lang = useSelector(state => state?.lang?.lang)

    useEffect(()=>{
        setBlog(location?.state)
    }, [])

    return <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4"></div>
                </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li><Link to='/blogs'>Blogs</Link></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className='container'>
        <div className="mil-blog-card mil-mb-60">
                    <div className="mil-cover-frame mil-up">
                        <img src={blog?.image} alt="cover" className='lazyload' />
                    </div>
                    <div className="mil-post-descr">
                        <div className="mil-labels mil-up mil-mb-15">
                            <div className="mil-label mil-upper mil-accent">{blog?.category}</div>
                            <div className="mil-label mil-upper mil-muted">{blog?.date?.split("T")[0]}</div>
                        </div>
                        <h4 className="mil-up mil-mb-15 mil-muted">{blog[`title_${lang}`]}</h4>
                        <p className="mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: blog[`description_${lang}`]}}></p>
                    </div>
                </div>
    </div>
    </section>
}
export default Blog;