import { useEffect, useState } from 'react'
import './style.css'
import Tab from './Tab'
import Loader from '../../layouts/Loader'
import { Swiper, SwiperSlide } from 'swiper/react';

const Gallary = () => {
    const tabs = ["Beauty", "Fashion", "Product", "Life Style"]
    const [loading, setLoading] = useState(true)
    const [selectedTab, setSelectedTab] = useState("Beauty")

    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    },[])

    if(loading){
        return <Loader />
    }

    return <section className="gallary mil-dark-bg">
    <div className="container mil-p-120-30">
        <div className="row mil-mb-60">
            <div className="col-lg-6 col-xl-8">
                <h2 className="mil-up mil-muted">Gallary</h2>
            </div>
        </div>
    
        <Swiper
            modules={[]}
            navigation={false}
            slidesPerView={3.5}
            spaceBetween={10}
            pagination={{
                clickable: true
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            breakpoints={{
                640: { slidesPerView: 3 },
                768: { slidesPerView: 4 },
                1024: { slidesPerView: 7 },
            }}
            className="mySwiper"
        >
            {tabs?.map((tab,index)=>{
                return <SwiperSlide key={index}>
                    <p  className={`${tab === selectedTab ? 'active tab' : 'tab'}`}
                        key={index}
                        onClick={()=> setSelectedTab(tab)}
                    >{tab}</p>
                </SwiperSlide>
            })}
        </Swiper>

        {selectedTab === "Beauty" && <Tab type='beauty' />}
        {selectedTab === "Fashion" && <Tab type='fashion' />}
        {selectedTab === "Product" && <Tab type='product' />}
        {selectedTab === "Life Style" && <Tab type='life_style' />}
    </div>
</section>
}
export default Gallary