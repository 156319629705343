import { useEffect, useState } from "react"
import VideoService from "../../services/VideoService"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomLoader from "../../layouts/CustomLoader";

const  SwiperComp = ({type, setModal, setChooseVideo}) => {
    let [data, setData] = useState([])
    let [loading, setLoading] = useState(false)
    let [hasData, setHasData] = useState(null)
    const videoService = new VideoService()

    useEffect(() => {
        let ty = type?.value
        setLoading(true)
        videoService?.getList(ty).then(res=>{
            if(res?.status){
                setData(res?.data?.data)
                if(res?.data?.data?.length > 0) {
                    setHasData(1)
                } else {
                    setHasData(0)
                }
            }
            setLoading(false)
        })
    }, [type])

    let SwiperComponent = ({arr, setModal, slidesView}) => {
        return <Swiper
            modules={[Navigation]}
            navigation={true}
            slidesPerView={slidesView}
            spaceBetween={10}
            pagination={{
                clickable: true
            }}
            breakpoints={{
                640: { slidesPerView: slidesView },
                768: { slidesPerView: slidesView },
                1024: { slidesPerView: slidesView },
            }}
            className="mySwiper"
        >
            {arr?.map((vid,index)=>{
                return <SwiperSlide key={index}>
                    <video
                        onClick={()=> {
                            setChooseVideo(vid)
                            setModal(true)
                        }}
                        src={vid}
                        controls={false}
                        className='w-100'
                        style={{
                            height: '100vh'
                        }}
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                </SwiperSlide>
            })}
        </Swiper>
    }

    if(loading){
        return <div className="mt-5">
            <CustomLoader />
        </div>
    }

    return <div>
        {hasData === 1 && <div className="mt-4">
            <SwiperComponent arr={[...data.map(item => item.one), ...data.map(item => item.two), ...data.map(item => item.three)]} setModal={setModal} slidesView={1} />
        </div>}
        {hasData === 0 && <h4 className="mil-up mil-muted mt-5">No Videos</h4>}
        {/* <div className="mt-4">
            <SwiperComponent arr={[...data.map(item => item.two), ...data.map(item => item.three)]} setModal={setModal} slidesView={2} />
        </div> */}
    </div> 
}
export default SwiperComp;