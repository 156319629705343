import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Details = () => {
    const [data, setData] = useState({})
    // const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const location = useLocation()
    const lang = useSelector(state => state?.lang?.lang)
    
    useEffect(()=>{
        setData(location?.state)
        // let tit = `title_${lang}`
        let txt = `title_${lang}`
        // setTitle(tit)
        setText(txt)
    }, [location, lang])

    return <div style={{paddingTop: '150px'}}>
        <div className="container pb-5">
            {data?.details?.map((res, index) => {
                return <div className="mb-5 row" key={index}>
                   {(res?.image.includes('.mp4') || res?.image.includes('.m4v') || res?.image.includes('.m4p')) ? <video
                        src={res?.image}
                        className='w-100 h-100'
                        autoPlay
                        loop
                        muted
                        playsInline
                        controls={true}
                    /> : <img 
                        src={res?.image} 
                        alt='second' 
                        className="w-100 lazyload" 
                        // style={{height: '400px'}}
                    />}
                    <p className="cus mil-up my-3 mil-mb-15 mil-muted" style={{fontSize: '22px'}} dangerouslySetInnerHTML={{ __html: res[text] }}></p>
                    {res?.link && <a href={res?.link} style={{color:'red', fontSize: '20px'}} target="_blank" rel="noreferrer">
                        Link
                    </a>}
                </div>
            })}
        </div>
    </div>
}
export default Details;