import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/homePic";

export default class HomeService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getBanner() {
        return http.get(`${apiEndpoint}/homeText`);
    }

    getAbout() {
        return http.get(`${apiEndpoint}/getAbout`);
    }

    getServices() {
        return http.get(`${apiEndpoint}/getServices`);
    }

    getClients() {
        return http.get(`${apiEndpoint}/client`);
    }

    getBlogs() {
        return http.get(`${apiEndpoint}/blog`);
    }

    getPartners() {
        return http.get(`${apiEndpoint}/partner`);
    }

    getDesign() {
        return http.get(`${apiEndpoint}/webDesign`);
    }

    getMeta() {
        return http.get(`${apiEndpoint}/metaStatic`);
    }
}
