import { useEffect, useState } from 'react'
import img from '../../assets/home-logo.webp'
import './style.css'
import AppointmentService from '../../services/AppointmentService'
import { toast } from 'react-toastify'

const Appointment = () => {
    const times = [
        {from: '5:00', to: '6:00', value: 1},
        {from: '6:15', to: '7:15', value: 2},
        {from: '7:30', to: '8:30', value: 3},
        {from: '8:45', to: '9:45', value: 4},
        {from: '10:00', to: '11:00', value: 5}
    ]
    const [formData, setFormData] = useState({
        name: '',
        phone: ''
    })
    const [type, setType] = useState('')
    const [typesOptions, setTypeOptions] = useState([])
    const [time, setTime] = useState('')
    const [date, setDate] = useState('')
    const [fullTime, setFullTime] = useState(false)
    const [isBlock, setIsBlock] = useState(false)
    const initialPossible = [
        {num: 1, possible: true},
        {num: 2, possible: true},
        {num: 3, possible: true}, 
        {num: 4, possible: true},
        {num: 5, possible: true},
        {num: 6, possible: true}
    ]
    const [isPossible, setIsPossible] = useState(initialPossible)
    const appointmentService = new AppointmentService()

    useEffect(()=>{
        appointmentService.appointmentTypes().then(res=>{
            if(res?.status){
                let response = res?.data?.data?.map(re => re?.type)
                setTypeOptions(response)
            }
        })
    },[])

    useEffect(()=>{
        if(!!date){
            appointmentService?.isBlock(date).then(res=>{
                if(res?.status){
                    setIsBlock(res?.data?.data)
                    if(!res?.data?.data){
                        appointmentService?.getBookingPossibility(date).then(res2 => {
                            if(res2?.status){
                                setIsPossible(res2?.data?.data)
                                setTime('')
                                setFullTime(false)
                            }
                        })
                    } else {
                        toast.error('Day is Block')
                    }
                }
            })
        }
    },[date])

    const bookNow = () => {
        if(!formData?.name || !formData?.phone || !date || !time || !type){
            toast.error('Complete All Phases')
            return
        }
        let data = {
            date: date,
            num: time?.value,
            type: type,
            name: formData?.name,
            phone: formData?.phone,
        }
        appointmentService.book(data).then(res=>{
            if(res?.status){
                toast.success('Booking Successful.')
                setDate('')
                setTime('')
                setType('')
                setFormData({
                    name: '',
                    phone: ''
                })
                setFullTime(false)
                setIsPossible(initialPossible)
            }
        }).catch((e) => {
            toast.error(e.response?.data?.message?.replaceAll('_',' '))
        })
    }

    return <div className="appointment mil-p-0-120">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <img src={img} alt='icon' className='w-100 lazyload' />
                </div>
                <div className="col-md-8">
                    <h4 className='title'>Appointment</h4>
                    <p>Appointment Timings: 5pm : 11pm</p>
                    <div className='form'>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <label>Name:</label>
                                <input 
                                    type='text' 
                                    name='name' 
                                    style={{
                                        height: '40px',
                                        padding: '0 20px'
                                    }}
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={e=> setFormData({...formData, name: e.target?.value})}
                                />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label>Phone:</label>
                                <input 
                                    type='number' 
                                    name='phone' 
                                    style={{
                                        height: '40px',
                                        padding: '0 20px'
                                    }}
                                    placeholder='Phone'
                                    value={formData.phone}
                                    onChange={e=> setFormData({...formData, phone: e.target?.value})}
                                />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label>Type:</label>
                                <select 
                                    onChange={e=> setType(e.target?.value)}
                                    style={{
                                        height: '40px',
                                        padding: '0 20px',
                                        color: '#000',
                                        borderRadius: '3px'
                                    }}
                                >
                                    <option value=''>Select</option>
                                    {typesOptions?.map(res=>{
                                        return <option key={res} value={res}>{res}</option>
                                    })}
                                </select>
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label>Date:</label>
                                <input 
                                    type='date' 
                                    name='date' 
                                    style={{
                                        height: '40px',
                                        padding: '0 20px',
                                        width: '100%',
                                        minWidth: '100%'
                                    }}
                                    placeholder='Date'
                                    value={date}
                                    onChange={e=> setDate(e.target?.value)}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label>Time:</label>
                                <div className='d-flex times' style={{gap: '12px'}}>
                                {times?.map((timeS, index) => {
                                    return <p 
                                        key={index} 
                                        onClick={()=>{
                                            if(isBlock){
                                                return
                                            }
                                            if(isPossible[index]?.possible){
                                                setTime(timeS)
                                                setFullTime(false)
                                            }
                                        }}
                                        className={`time ${time?.from === timeS?.from ? 'active' : ''} ${(!isPossible[index]?.possible || isBlock) ? 'impossible' : 'possible'} `}>
                                        {timeS?.from}
                                        <br />to<br />
                                        {timeS?.to}
                                    </p>
                                })}
                                <p onClick={()=> {
                                    if(isBlock){
                                        return
                                    }
                                    if(isPossible[5]?.possible){
                                        setTime({from: 'full_time', value: 6})
                                        setFullTime(true)
                                    }
                                }}
                                    className={`time ${fullTime ? 'active' : ''} ${(!isPossible[5]?.possible || isBlock) ? 'impossible' : 'possible'}`}>
                                        Full Time
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type='submit' onClick={bookNow}>Book Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Appointment;