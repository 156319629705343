
import React, { useState} from 'react'
import VideoModal from './VideoModal'
import 'swiper/css/navigation';
import './style.css'
import SwiperComp from './SwiperCom.js';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

const Video = () => {
    const tabs = [
        {en: "Fashion and Beauty", value: 'fashion_and_beauty', ar: "الموضة و الجمال"}, 
        {en: "Product", value: 'product', ar: "منتجات"}, 
        {en: "3d Animation", value: '3d_animation', ar: "رسوم متحركة ثلاثية الأبعاد"}, 
        {en: "Podcast & Tv show", value: 'podcast_tv_show', ar: "العرض التلفزيوني و البودكاست"}, 
        {en: "Commercials Advertising", value: 'commercials_advertising', ar: "إعلان تجاري"}, 
        {en: "Behind the Scene", value: 'behind_the_scene', ar: "خلف الكواليس"}, 
        {en: "Other", value: 'other', ar: "أخرى"}, 
    ]
    const [modal, setModal] = useState(false)
    const [chooseVideo, setChooseVideo] = useState(false)
    const [selectedTab, setSelectedTab] = useState(tabs[0])
    const lang = useSelector(state => state?.lang?.lang)


    return <div className="video">
        <div className="container mil-p-120-30">
        <div className="row mil-mb-60">
            <div className="col-lg-6 col-xl-8">
                <h2 className="mil-up mil-muted">Videos</h2>
            </div>
        </div>

        <div className='mb-4' style={{direction: lang === 'ar' ? 'rtl' : 'ltr' ,borderBottom: '1px solid #dedede'}}>
        <Swiper
            modules={[]}
            navigation={false}
            slidesPerView={1.5}
            spaceBetween={10}
            pagination={{
                clickable: true
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            breakpoints={{
                640: { slidesPerView: 3 },
                768: { slidesPerView: 4 },
                1024: { slidesPerView: 5 },
            }}
            className="mySwiper"
        >
            {tabs?.map((tab,index)=>{
                return <SwiperSlide key={index}>
                    <p
                        className={`${tab.en === selectedTab.en ? 'active tab' : 'tab'}`}
                        key={index}
                        onClick={()=> setSelectedTab(tab)}
                    >{lang === 'ar' ? tab['ar'] : tab['en']}</p>
                </SwiperSlide>
            })}
        </Swiper>
        </div>

        {selectedTab?.en === tabs[0]?.en && <div>
            <SwiperComp type={tabs[0]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[1]?.en && <div>
            <SwiperComp type={tabs[1]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[2]?.en && <div>
            <SwiperComp type={tabs[2]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[3]?.en && <div>
            <SwiperComp type={tabs[3]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[4]?.en && <div>
            <SwiperComp type={tabs[4]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[5]?.en && <div>
            <SwiperComp type={tabs[5]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}
        {selectedTab?.en === tabs[6]?.en && <div>
            <SwiperComp type={tabs[6]} setModal={setModal} setChooseVideo={setChooseVideo} />
        </div>}

        {modal && <VideoModal show={modal} onClose={()=> setModal(false)} video={chooseVideo} />}
        </div>
    </div>
}
export default Video;