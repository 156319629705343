import { useEffect } from 'react';
import flash from '../assets/flash.mp3'
const Loader = () => {
    useEffect(() => {
        let AudioContext = window.AudioContext // Default
        || window.webkitAudioContext // Safari and old versions of Chrome
        || false; 
        if (AudioContext) {
            const audio = new Audio(flash);
            audio.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        }
    }, []);
    
    return <>
        <div className="flash-body">
            <div className="camera-flash"></div>
        </div>
    </>
}
export default Loader