import { useEffect, useState } from "react"
import HomeService from "../../services/HomeService"

const Background = () =>{
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(() => {
        if (!data || data.length === 0) {
            homeService.getList().then(res => {
                if (res?.status) {
                    let fetchedData = res?.data?.data;
                    setData([...fetchedData])
                }
            }).catch(() => setData([]));
        }

        return () => {
            setData([])
        };
    }, []);

    return <div className='dark-bg main-animate'>
    {data?.length && <div className={`home-animate grid-container p-2`}>
        {data?.map((photo, index) => {
            return <div className="row mb-2" key={index}>
                <div className="col-md-12 col-12" style={{padding: '0'}}>
                    <img className='w-100 h-100' src={photo?.one} alt='gallary' />
                </div>
                {/* <div className="col-md-6 col-12" style={{padding: '0 3px'}}>
                    <img className='w-100 h-100' src={photo?.two} alt='gallary' />
                </div>
                <div className="col-md-3 col-12" style={{padding: '0 0 0 3px'}}>
                    <img className='w-100 h-100' src={photo?.three} alt='gallary' />
                </div> */}
            {/* <div className='grid-item' style={{gridColumn: '1 / span 1' ,gridRow: (index+1)}}>
                <img className='w-100 h-100' src={photo?.one} alt='gallary' />
            </div>
            <div className='grid-item' style={{gridColumn: '2 / span 2' ,gridRow: (index+1)}}>
                <img className='w-100 h-100' src={photo?.two} alt='gallary' />
            </div>
            <div className='grid-item' style={{gridColumn: '4 / span 1' ,gridRow: (index+1)}}>
                <img className='w-100 h-100' src={photo?.three} alt='gallary' />
            </div> */}
            </div>
        })}
    </div>}
</div>
}
export default Background;