import { useEffect, useState } from 'react'
import StaticPagesServices from '../../services/StaticPagesService'
import ClientsService from '../../services/ClientsService'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from 'swiper/react';

const About = () => {
    const [data, setData] = useState([])
    const [customData, setCustomData] = useState([])
    const [clients, setClients] = useState([])
    const lang = useSelector(state => state?.lang?.lang)
    const navigate = useNavigate()

    useEffect(()=>{
        new StaticPagesServices()?.getList({type: 'about'}).then(res=>{
            if(res?.status){
                let info = res?.data?.data?.filter(stat => !!stat?.title_en && !!stat?.job)
                let cust = res?.data?.data?.filter(stat => !stat?.title_en && !stat?.job)
                setData(info)
                setCustomData(cust)
            }
        })

        new ClientsService()?.getList().then(res=>{
            if(res?.status){
                setClients(res?.data?.data)
            }
        })
    },[])

    return <section id="about" className="mil-dark-bg">
    <div className="container mil-p-120-60">
        <div className="row mil-mb-90">
            <div className="col-lg-6 col-xl-8">
                <h2 className="mil-up  mil-muted">About Us</h2>
            </div>
        </div>

        {data?.map((res, index) => {
            if(index % 2 === 0){
                return <div key={index} className="row justify-content-between">
                <div className={`col-lg-6 col-xl-5 ${lang === 'ar' ? 'rtl' : 'ltr'}`} >
                    <h4 className="mil-up mil-mb-30 mil-muted">{res[`title_${lang}`]}</h4>
                    <p className="cus mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: res[`description_${lang}`] }}></p>
                </div>
                <div className="col-lg-6 cursor-pointer" onClick={()=> {
                        if(!!res?.details?.length ){
                            navigate('/about/details', {state: res})
                        }
                    }}>
                    <div className="mil-about-photo mil-mb-90">
                            <img src={res?.image} alt="img" className="mil-scale lazyload h-100 w-100" />
                            <p 
                                style={{
                                    color: 'red',
                                    margin: '8px 0',
                                    textAlign: 'center',
                                    fontWeight: '400',
                                    fontSize: '18px',
                                }}
                            >view more</p>
                            <h5 className="mil-up mt-3 mil-muted text-center">{res?.job}</h5>
                    </div>
    
                </div>
            </div>
            } else {
                return <div key={index} className="row justify-content-between">
                <div className="col-lg-6">
                    <div className="mil-about-photo mil-mb-90 cursor-pointer"  onClick={()=> {
                        if(!!res?.details?.length){
                            navigate('/about/details', {state: res})
                        }
                    }}>
                        <img src={res?.image} alt="img" className="mil-scale lazyload h-100 w-100" />
                        <p 
                            style={{
                                color: 'red',
                                margin: '8px 0',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '20px',
                            }}
                        >view more</p>
                        <h5 className="mil-up mt-3 mil-muted text-center">{res?.job}</h5>
                    </div>
                </div>
                <div className={`col-lg-6 col-xl-5 ${lang === 'ar' ? 'rtl' : 'ltr'}`}>
                    <h4 className="mil-up mil-mb-30 mil-muted">{res[`title_${lang}`]}</h4>
                    <p className="cus mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: res[`description_${lang}`] }}></p>
                </div>
            </div>
            }
        })}

        <hr />

        {customData?.map((res, index) => {
            return <div className="row justify-content-between mb-5" key={index}>
                <div className="col-12">
                    <div className="mil-about-photo mil-mb-60 ">
                        <img src={res?.image} alt="img" className="mil-scale lazyload h-100 w-100" />
                    </div>
                </div>
                <div className={`col-lg-12 col-12 ${lang === 'ar' ? 'rtl' : 'ltr'}`}>
                    <p className="cus mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: res[`description_${lang}`] }}></p>
                </div>
            </div>
        })}


        <h3 className="mil-up mil-mb-30 mil-muted">Our Client's</h3>
        <Swiper
            modules={[Autoplay]}
            navigation={true}
            slidesPerView={5}
            spaceBetween={10}
            pagination={{
                clickable: true
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            breakpoints={{
                640: { slidesPerView: 4 },
                768: { slidesPerView: 5 },
                1024: { slidesPerView: 5 },
            }}
            className="mySwiper"
        >
            {clients?.map((client,index)=>{
                return <SwiperSlide key={index}>
                    <img src={client?.image} className='lazyload clients-image' alt="img" style={{
                        height: '150px',
                        width: '150px',
                        borderRadius: '50%'
                    }} />
                </SwiperSlide>
            })}
        </Swiper>
        {/* {clients?.length > 0 && <div className='mt-5'>
            <h3 className='mil-muted'>Our Client's</h3>
            <div className='row mt-3'>
                {clients?.map((res, index)=> {
                    return <div key={index} className='col-md-3'>
                    <img src={res?.image} alt="img" style={{
                        height: '150px',
                        width: '150px',
                        borderRadius: '50%'
                    }} />
                </div>
                })}
            </div>
        </div>} */}
    </div>
</section>
}
export default About