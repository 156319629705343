import { API_BASE_URL_ENV } from "../common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/appointment";

export default class AppointmentService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    // Book
    book(data) {
        return http.post(`${this.apiEndpoint}/book`, data);
    }

    // Booking Possibility
    getBookingPossibility(date) {
        if(!!date){
            return http.get(`${this.apiEndpoint}/getBookingPossibility?date=${date}`);
        }
        return http.get(`${this.apiEndpoint}/getBookingPossibility`);
    }

    // isBlock
    isBlock(date) {
        return http.get(`${this.apiEndpoint}/isBlock?date=${date}`);
    }

    // Appointment Type
    appointmentTypes() {
        return http.get(`${this.apiEndpoint}/appointmentType`);
    }
}