import 'bootstrap/dist/css/bootstrap.min.css';
import './common/css/style.css';
import './common/scss/style.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import Navbar from './layouts/Navbar';
import Home from './pages/Home';
import Error404 from './layouts/Error404';
import Preloader from './layouts/Preloader';
import Gallary from './pages/Gallary';
import Video from './pages/Video';
import Appointment from './pages/Appointment';
import About from './pages/About';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import Services from './pages/Services';
import Details from './pages/About/Details';
import "react-toastify/dist/ReactToastify.css";
import './App.css'
import 'swiper/css'
import "../node_modules/video-react/dist/video-react.css";

function App() {

  return (
    <>
    <ToastContainer />
    <Preloader />
    <BrowserRouter>
      <Navbar />
      <div className="mil-wrapper" id='top'>   
      <div className="mil-content">
          <div id="swupMainm" className="mil-main-transitionn mil-dark-bg">
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/gallary' element={<Gallary />} />
                <Route path='/video' element={<Video />} />
                <Route path='/blogs' element={<Blogs />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/services' element={<Services />} />
                <Route path='/appointment' element={<Appointment />} />
                <Route path='/about' element={<About />} />
                <Route path='/about/details' element={<Details />} />
                <Route path='*' element={<Error404 />} />
            </Routes>
          </div>
      </div> 
      </div>
      </BrowserRouter>
    </>);
}

export default App;
