import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogsService from "../../services/BlogsService";
import { useSelector } from "react-redux";

const Blogs = () => {
    const [global, setGlobal] = useState([])
    const [picMe, setPicMe] = useState([])
    const [video, setVideo] = useState('')
    const lang = useSelector(state => state?.lang?.lang)
    const blogsService = new BlogsService()

    useEffect(()=>{
        blogsService.getList('global').then(res=>{
            if(res?.status){
                let data = res?.data?.data
                setGlobal(data)
            }
        })
        blogsService.getBlogVideo().then(res=>{
            if(res?.status){
                let data = res?.data?.data?.url
                setVideo(data)
            }
        })
    },[])

    useEffect(()=>{
        blogsService.getList('pic_me').then(res=>{
            if(res?.status){
                let data = res?.data?.data
                setPicMe(data)
            }
        })
    },[])

    return <div>
        <div className="container mil-p-120-30">
            <div className="row mil-mb-60">
                <div className="col-lg-6 col-xl-8">
                    <h2 className="mil-up mil-muted">Global Blogs</h2>
                </div>
            </div>
            
            <div className="row">
            {global?.map(blog=>{
                return <div key={blog?.id} className="col-lg-4 mb-4">
                    <a href={blog.link} target="_blank" state={blog} className="mil-blog-card mil-mb-60" rel="noreferrer">
                        <div className="mil-cover-frame mil-up">
                            <img src={blog?.image} alt="cover" className="lazyload" style={{objectFit: "fill"}} />
                        </div>
                        <div className="mil-post-descr">
                            <h4 className="mil-up mil-mb-15 mil-muted">{blog[`title_${lang}`]}</h4>
                            <div className="mil-link icons mil-up">
                                <span className='mil-accent'>
                                    Read more
                                    <i className="fa fa-arrow-right mil-accent icon-white" ></i>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            })}
            </div>
        </div>

        {!!video && <div className="container mil-p-30-30">
            <video
                src={video}
                controls={true}
                className='w-100'
                autoPlay
                loop
                muted
                playsInline
            />
        </div>}

        <div className="container mil-p-120-30">
            <div className="row mil-mb-60">
                <div className="col-lg-6 col-xl-8">
                    <h2 className="mil-up mil-muted">Picme Blogs</h2>
                </div>
            </div>
            
            <div className="row">
            {picMe?.map(blog=>{
                return <div key={blog?.id} className="col-lg-4 mb-4">
                    <Link to="/blog" state={blog} className="mil-blog-card mil-mb-60">
                        <div className="mil-cover-frame mil-up">
                            <img src={blog?.image} alt="cover" className="lazyload" style={{objectFit: "fill"}}/>
                        </div>
                        <div className="mil-post-descr">
                            <h4 className="mil-up mil-mb-15 mil-muted">{blog[`title_${lang}`]}</h4>
                            <p className="mil-post-text mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: blog?.description_en }}></p>
                            <div className="mil-link icons mil-up">
                                <span className='mil-accent'>
                                    Read more
                                    <i className="fa fa-arrow-right mil-accent icon-white" ></i>
                                </span>
                            </div>
                        </div>
                    </Link>
                </div>
            })}
            </div>
        </div>
    </div>
}
export default Blogs;